import _customEvent from "custom-event";
import _eventmap from "./eventmap";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var customEvent = _customEvent;
var eventmap = _eventmap;
var doc = document;
var addEvent = addEventEasy;
var removeEvent = removeEventEasy;
var hardCache = [];

if (!_global.addEventListener) {
  addEvent = addEventHard;
  removeEvent = removeEventHard;
}

function addEventEasy(el, type, fn, capturing) {
  return el.addEventListener(type, fn, capturing);
}

function addEventHard(el, type, fn) {
  return el.attachEvent("on" + type, wrap(el, type, fn));
}

function removeEventEasy(el, type, fn, capturing) {
  return el.removeEventListener(type, fn, capturing);
}

function removeEventHard(el, type, fn) {
  return el.detachEvent("on" + type, unwrap(el, type, fn));
}

function fabricateEvent(el, type, model) {
  var e = eventmap.indexOf(type) === -1 ? makeCustomEvent() : makeClassicEvent();

  if (el.dispatchEvent) {
    el.dispatchEvent(e);
  } else {
    el.fireEvent("on" + type, e);
  }

  function makeClassicEvent() {
    var e;

    if (doc.createEvent) {
      e = doc.createEvent("Event");
      e.initEvent(type, true, true);
    } else if (doc.createEventObject) {
      e = doc.createEventObject();
    }

    return e;
  }

  function makeCustomEvent() {
    return new customEvent(type, {
      detail: model
    });
  }
}

function wrapperFactory(el, type, fn) {
  return function wrapper(originalEvent) {
    var e = originalEvent || _global.event;
    e.target = e.target || e.srcElement;

    e.preventDefault = e.preventDefault || function preventDefault() {
      e.returnValue = false;
    };

    e.stopPropagation = e.stopPropagation || function stopPropagation() {
      e.cancelBubble = true;
    };

    e.which = e.which || e.keyCode;
    fn.call(el, e);
  };
}

function wrap(el, type, fn) {
  var wrapper = unwrap(el, type, fn) || wrapperFactory(el, type, fn);
  hardCache.push({
    wrapper: wrapper,
    element: el,
    type: type,
    fn: fn
  });
  return wrapper;
}

function unwrap(el, type, fn) {
  var i = find(el, type, fn);

  if (i) {
    var wrapper = hardCache[i].wrapper;
    hardCache.splice(i, 1); // free up a tad of memory

    return wrapper;
  }
}

function find(el, type, fn) {
  var i, item;

  for (i = 0; i < hardCache.length; i++) {
    item = hardCache[i];

    if (item.element === el && item.type === type && item.fn === fn) {
      return i;
    }
  }
}

exports = {
  add: addEvent,
  remove: removeEvent,
  fabricate: fabricateEvent
};
export default exports;
export const add = exports.add,
      remove = exports.remove,
      fabricate = exports.fabricate;